// src/App.js
import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate  } from 'react-router-dom';
import BrainList from './components/BrainList';
import CreateBrain from './components/CreateBrain';
import Chat from './components/Chat';
import CreateBrainPage from './pages/CreateBrainPage';
import Home from './pages/Home';
import BrainDetail from './pages/BrainDetail';
import Chats from './pages/chats';
import Sidebar from './components/Sidebar';
import { getchats, creatchats, getBrains, delBrain } from './api/api';

function App() {
  const [chatsdata, setchatsdata] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchchats = async () => {
      const response = await getchats();
      console.log(response.data.chats);
      setchatsdata(response.data.chats);
    };
    fetchchats();
  }, []);

  const [selectedChat, setSelectedChat] = useState(null);


  const handleSelectChat = (chat) => {
    setSelectedChat(chat);
  };

  const handleCreateChat = async () => {
    // const newChat = await creatchats({ name: "New Chat"});
    // // console.log())
    // setchatsdata([...chatsdata, newChat.data]);
    // setSelectedChat(newChat);
    setSelectedChat([])
    navigate(`/chat`);
  };

  const [brains, setBrains] = useState([]);

  const fetchBrains = async () => {
    const response = await getBrains();
    console.log(response);
    setBrains(response.data.brains);
  };
  useEffect(() => {
    fetchBrains();
  }, []);
    const delBrains = async () => {
      const response = await delBrain();
      console.log(response);
      fetchBrains();
    };
  return (
    // <Router>
    <>      <Routes>
        <Route path="/brains" element={<BrainList />} />
      </Routes>
        <div style={{ display: 'flex' }}>
        <Sidebar brains={brains} chats={chatsdata} onSelectChat={handleSelectChat} onCreateChat={handleCreateChat} delBrains={delBrains}/>
        <div style={{ marginLeft: '260px', padding: '20px', flex: 1, height: '100vh' }}>
          <Routes>
            <Route path="/chat" element={<Chat selectedChat={selectedChat} onUpdateChats={setchatsdata} chatsdata={chatsdata} setSelectedChat={setSelectedChat}/>} />
            <Route path="/chat/:chatId" element={<Chat selectedChat={selectedChat} onUpdateChats={setchatsdata} chatsdata={chatsdata} setSelectedChat={setSelectedChat} />} />
            <Route path="/" element={<Chat selectedChat={selectedChat} onUpdateChats={setchatsdata} chatsdata={chatsdata} setSelectedChat={setSelectedChat}/>} />
            <Route path="/create-brain" element={<CreateBrainPage />} />
            <Route path="/braindetail/:brainid" element={<BrainDetail />} />
          </Routes>
        </div>
        </div></>

    // </Router>
  );
}

export default App;
