import React, { useState } from 'react';
import { uploadurl, creatchats, delchat } from '../api/api';

const UrlUpload = ({ brainId, fetchknowledge }) => {
  const [url, seturl] = useState('');

  const handleurlChange = (e) => {
    seturl(e.target.value);
  };

  const handleUpload = async () => {
    if (!url) return;
    try {
      const res = await creatchats({ name: "New Chat" });
      const chat_id = res.data.chat_id;
      if (chat_id) {
        await uploadurl(brainId, url, chat_id);
        alert('URL uploaded successfully!');
        await delchat(chat_id);
        if (fetchknowledge) fetchknowledge();
      }
    } catch (error) {
      console.error("Error uploading URL:", error);
      alert('Failed to upload URL');
    }
  };

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-lg w-full max-w-md mx-auto mt-8">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Upload URL</h2>
      <input
        type="text"
        value={url}
        onChange={handleurlChange}
        placeholder="Enter URL"
        className="block w-full text-gray-700 border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:border-blue-500"
        required
      />
      <button
        onClick={handleUpload}
        className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md font-semibold transition duration-200"
      >
        Upload
      </button>
    </div>
  );
};

export default UrlUpload;
