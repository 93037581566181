import React, { useState, useEffect } from 'react';
import { getBrainDetail, getknowledge, deleteknow } from '../api/api';
import { useParams } from 'react-router-dom';
import DocumentUpload from '../components/DocumentUpload';
import UrlUpload from '../components/urlUpload';

const BrainDetail = () => {
  const { brainid } = useParams();
  const [brain, setBrain] = useState([]);
  const [knowledge, setKnowledge] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingUrl, setIsUpdatingUrl] = useState(false);

  const fetchBrain = async () => {
    const response = await getBrainDetail(brainid);
    setBrain(response.data);
  };

  useEffect(() => {
    fetchBrain();
  }, [brainid]);

  const fetchKnowledge = async () => {
    const response = await getknowledge(brainid);
    setKnowledge(response.data.knowledges);
  };

  useEffect(() => {
    fetchKnowledge();
  }, [brainid]);

  const deleteKnowledge = async (id) => {
    await deleteknow(id);
    fetchKnowledge();
  };

  return (
    <div className="p-8 max-w-4xl mx-auto bg-gray-50 shadow-lg rounded-xl mt-10 space-y-8">
      {/* Header Section */}
      <div className="text-center space-y-2">
        <h2 className="text-4xl font-extrabold text-indigo-600">Brain Details</h2>
        <p className="text-lg text-gray-700">Manage and update your brain knowledge data below</p>
      </div>

      {/* Brain Information */}
      <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
        <h3 className="text-2xl font-semibold text-gray-800">Name: <span className="font-normal">{brain.name}</span></h3>
        <p className="text-lg font-medium text-gray-700 mt-2">Description: {brain.description}</p>
      </div>

      {/* Knowledge List */}
      <div>
        <h2 className="text-3xl font-semibold text-gray-800 mb-4">Knowledge</h2>
        <div className="grid gap-4">
          {knowledge.map((item, index) => (
            <div key={item.id} className="p-5 bg-gray-100 rounded-lg shadow-sm border border-gray-200 flex justify-between items-center transition-transform hover:scale-105">
              <div>
                <h3 className="text-lg font-semibold text-indigo-600">{index + 1}. {item.file_name}</h3>
                <p className={`text-sm ${item.status === 'UPLOADED' ? 'text-green-500' : 'text-red-500'}`}>{item.status}</p>
              </div>
              <button
                onClick={() => deleteKnowledge(item.id)}
                className="text-red-500 hover:text-red-600 font-semibold py-1 px-3 rounded-md border border-red-300"
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Upload Buttons */}
      <div className="flex gap-4 justify-center mt-6">
        <button
          onClick={() => setIsUpdating(true)}
          className="bg-indigo-500 text-white font-medium py-2 px-5 rounded-lg shadow-lg hover:bg-indigo-600 transition"
        >
          Add Document
        </button>
        <button
          onClick={() => setIsUpdatingUrl(true)}
          className="bg-indigo-500 text-white font-medium py-2 px-5 rounded-lg shadow-lg hover:bg-indigo-600 transition"
        >
          Add URL
        </button>
      </div>

      {/* Document and URL Upload Sections */}
      {isUpdating && (
        <div className="mt-6 p-6 bg-white rounded-lg shadow-lg border border-gray-200">
          <DocumentUpload brainId={brainid} fetchknowledge={fetchKnowledge} />
        </div>
      )}
      {isUpdatingUrl && (
        <div className="mt-6 p-6 bg-white rounded-lg shadow-lg border border-gray-200">
          <UrlUpload brainId={brainid} fetchknowledge={fetchKnowledge} />
        </div>
      )}
    </div>
  );
};

export default BrainDetail;
