// src/components/BrainList.js
import React, { useEffect, useState } from 'react';
import { getBrains } from '../api/api';
import { Link } from 'react-router-dom';

const BrainList = () => {
  const [brains, setBrains] = useState([]);

  useEffect(() => {
    const fetchBrains = async () => {
      const response = await getBrains();
      console.log(response);
      setBrains(response.data.brains);
    };
    fetchBrains();
  }, []);

  return (
    <div>
      <h2>Your Brains</h2>
      <ul>
        {brains.map(brain => (
          <Link to={`/chat/${brain.id}`}><li key={brain.id}>{brain.name}</li></Link>
        ))}
      </ul>
    </div>
  );
};

export default BrainList;
