// src/api/api.js
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;
const TOKEN_ENDPOINT = `http://23.88.45.179:54321/auth/v1/token?grant_type=password`;
const TOKEN = 'yJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwOi8vMTI3LjAuMC4xOjU0MzIxL2F1dGgvdjEiLCJzdWIiOiIzOTQxOGUzYi0wMjU4LTQ0NTItYWY2MC03YWNmY2MxMjYzZmYiLCJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNzMwMTExNTYzLCJpYXQiOjE3MzAxMDc5NjMsImVtYWlsIjoiYWRtaW5AcXVpdnIuYXBwIiwicGhvbmUiOiIiLCJhcHBfbWV0YWRhdGEiOnsicHJvdmlkZXIiOiJlbWFpbCIsInByb3ZpZGVycyI6WyJlbWFpbCJdfSwidXNlcl9tZXRhZGF0YSI6e30sInJvbGUiOiJhdXRoZW50aWNhdGVkIiwiYWFsIjoiYWFsMSIsImFtciI6W3sibWV0aG9kIjoicGFzc3dvcmQiLCJ0aW1lc3RhbXAiOjE3MzAwNDc2Mjd9XSwic2Vzc2lvbl9pZCI6IjQxZmJhMjhhLWZhOTYtNGU1ZS05MDRlLTdjYjA2NWI1MGIyZSIsImlzX2Fub255bW91cyI6ZmFsc2V9.ThHr65VbCO7xDkF9QXbtXUUo0SVVoive0GOMo0DrSYo';

// Create a default axios instance with the token set in headers
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Authorization': `Bearer ${TOKEN}`
  }
});

const getAccessToken = () => localStorage.getItem("access_token");
const getRefreshToken = () => localStorage.getItem("refresh_token");

// Set access token header
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${getAccessToken()}`;

// Request interceptor to add Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Function to refresh token
const refreshAuthToken = async () => {
  try {
    const refreshToken = getRefreshToken();
    // if (!refreshToken) throw new Error("No refresh token available");

    const response = await axios.post(TOKEN_ENDPOINT, {
      // grant_type: 'refresh_token',
      // refresh_token: refreshToken
      email: "admin@quivr.app",
      gotrue_meta_security: {},
      password : "admin",
    });

    const { access_token, refresh_token } = response.data;

    // Update tokens in local storage
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("refresh_token", refresh_token);

    // Update Axios headers
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
    return access_token;
  } catch (error) {
    console.error("Token refresh failed:", error);
    throw error;
  }
};

// Response interceptor to handle 401 errors
// Response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    console.log("Error status:", error.response.status);
    
    // If 401 Unauthorized error, attempt token refresh
    if (error.response && error.response.status === 401) {
      // originalRequest._retry = true; // Mark request to avoid infinite loop
      try {
        console.log("Error status:", error.response.status);
        const newAccessToken = await refreshAuthToken();
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        console.log("Retrying with new access token...");
        return axiosInstance(originalRequest); // Retry original request with new token
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        // Optionally, handle token expiration (e.g., redirect to login)
      }
    }
    return Promise.reject(error);
  }
);



export const createBrain = async (brainData) => {
  return await axiosInstance.post('/brains', brainData);
};

export const getBrains = async () => {
  try {
    const response = await axiosInstance.get('/brains');
    return response;
  } catch (error) {
    console.error("Error fetching brains:", error.response.status);
    throw error;
  }
};


export const getBrainDetail = async (brainId) => {
  return await axiosInstance.get(`/brains/${brainId}?brain_id=${brainId}`);
};
export const delBrain = async () => {
  let brainId = localStorage.getItem("selectedBrain");
  return await axiosInstance.delete(`/brains/${brainId}/subscription`);
};

export const getknowledge = async (brainId) => {
  return await axiosInstance.get(`/knowledge?brain_id=${brainId}`);
};

export const deleteknow = async (id) => {
  let brainid = localStorage.getItem("selectedBrain");
  return await axiosInstance.delete(`/knowledge/${id}?brain_id=${brainid}`);
};

export const uploadDocument = async (brainId, uploadFile, chat_id) => {
  const formData = new FormData();
  formData.append('uploadFile', uploadFile);
  return await axiosInstance.post(`/upload?bulk_id=${chat_id}&brain_id=${brainId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};
export const uploadurl = async (brainId, url, chat_id) => {
  return await axiosInstance.post(`/crawl?bulk_id=${chat_id}&brain_id=${brainId}`, {url : "https://jsonlint.com/"});
};

export const getchats = async () => {
  return await axiosInstance.get(`/chat`);
};

export const delchat = async (chat_id) => {
  return await axiosInstance.delete(`/chat/${chat_id}`);
};
export const getchathistory = async (chat_id) => {
  return await axiosInstance.get(`/chat/${chat_id}/history`);
};


export const creatchats = async (name) => {
  return await axiosInstance.post(`/chat`, name);
};

export const sendMessage = async (chatid, message) => {
  let brainid = localStorage.getItem("selectedBrain");

  return await axiosInstance.post(`chat/${chatid}/question/stream?brain_id=${brainid}`, message);
};


