// src/components/Sidebar.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Sidebar = ({ brains, chats, onSelectChat, onCreateChat, delBrains }) => {
  const [selectedBrain, setSelectedBrain] = useState(localStorage.getItem('selectedBrain') || '');

  useEffect(() => {
    if (!selectedBrain && brains.length > 0) {
      const defaultBrain = brains[0].id;
      setSelectedBrain(defaultBrain);
      localStorage.setItem('selectedBrain', defaultBrain);
    }
  }, [brains, selectedBrain]);

  const handleBrainChange = (event) => {
    const id = event.target.value;
    setSelectedBrain(id);
    localStorage.setItem('selectedBrain', id);
  };

  return (
    <div className="w-64 h-full bg-gray-800 text-white p-4 fixed top-0 left-0 overflow-y-auto">
      <a href="/"><h2 className="text-xl font-bold mb-4">MyBrain</h2></a>

      <div className="mb-4">
        <label htmlFor="brainSelect" className="block text-sm font-medium mb-1">Select Brain</label>
        <select
          id="brainSelect"
          value={selectedBrain}
          onChange={handleBrainChange}
          className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md"
        >
          {brains?.length > 0 ? (
            brains.map((brain) => (
              <option key={brain.id} value={brain.id}>
                {brain.name}
              </option>
            ))
          ) : (
            <option disabled>No brains available</option>
          )}
        </select>
      </div>

      <div className="flex gap-2 flex-col mb-2">
        <Link to={`/braindetail/${selectedBrain}`} className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md text-center">
          View Brain
        </Link>
      </div>
      <button
        onClick={delBrains}
        className="w-full bg-red-500 hover:bg-red-600 text-white px-4 py-2 mb-6 rounded-md"
      >
        Delete Brain
      </button>
      <div className="flex gap-2 flex-col mb-4">
        <Link to="/create-brain" className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md text-center">
          Create Brain
        </Link>
      </div>


      <h3 className="text-lg font-semibold mb-2">Chat List</h3>
      <ul className="space-y-2">
        {chats.length > 0 && chats?.map((chat) => (
          <li key={chat.chat_id} onClick={() => onSelectChat(chat)} className="text-gray-300">
            <Link
              to={`/chat/${chat.chat_id}`}
              className="block p-2 hover:bg-gray-700 rounded-md"
            >
              {chat.chat_name}
            </Link>
          </li>
        ))}
      </ul>

      {/* <button
        onClick={onCreateChat}
        className="w-full mt-4 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
      >
        Create New Chat
      </button> */}
    </div>
  );
};

export default Sidebar;
