import React, { useState } from 'react';
import { createBrain } from '../api/api';
import DocumentUpload from '../components/DocumentUpload';
import UrlUpload from '../components/urlUpload';

const CreateBrainPage = () => {
  const [form, setForm] = useState({
    name: '',
    description: '',
    temperature: 0,
    brain_type: 'doc',
    snippet_color: '',
    snippet_emoji: '',
  });
  
  const [isSuccess, setIsSuccess] = useState(false); 
  const [id, setId] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await createBrain(form);
      setIsSuccess(true);
      setId(res.data.id);
    } catch (error) {
      console.error("Error creating brain:", error);
      alert('Failed to create brain');
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-8 bg-gray-50 shadow-lg rounded-lg mt-10 space-y-8">
      {/* Title Section */}
      <h1 className="text-4xl font-bold text-indigo-600 text-center mb-4">Create Brain</h1>
      <p className="text-gray-700 text-center">Fill in the details to create a new Brain instance.</p>
      
      {/* Form */}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4">
          <input
            type="text"
            name="name"
            placeholder="Brain Name"
            onChange={handleChange}
            required
            className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <textarea
            name="description"
            placeholder="Description"
            onChange={handleChange}
            required
            className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>
        
        <button
          type="submit"
          className="w-full py-3 bg-indigo-600 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 transition duration-200"
        >
          Create Brain
        </button>
      </form>

      {/* Success Section */}
      {isSuccess && id && (
        <div className="space-y-6 mt-6 p-6 bg-green-50 border border-green-300 rounded-lg shadow-md">
          <h2 className="text-xl font-bold text-green-600 text-center">Brain Created Successfully!</h2>
          <p className="text-gray-600 text-center">You can now add documents or URLs to your Brain.</p>
          
          {/* Upload Components */}
          <div className="space-y-4">
            <DocumentUpload brainId={id} />
            <UrlUpload brainId={id} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateBrainPage;
