// src/components/Chat.js
import React, { useState, useEffect } from 'react';
import { sendMessage, creatchats, getchathistory } from '../api/api';
import { useNavigate, useParams } from 'react-router-dom';

const Chat = ({ selectedChat, onUpdateChats, chatsdata, setSelectedChat }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {chatId} = useParams();
  const [showchat, setshowchat] = useState(false);
  
  const fetchChatHistory = async () => {
    if (selectedChat) {
      try {
        const res = await getchathistory(selectedChat.chat_id);
        setMessages(res.data);
      } catch (error) {
        console.error("Error fetching chat history:", error);
      }
    }
  };

  useEffect(() => {
    fetchChatHistory();
  }, [selectedChat]);
  useEffect(() => {
    console.log(selectedChat);
  }, [selectedChat]);
  useEffect(() => {
    console.log(chatId);
    if (chatId) {
      setshowchat(true);
    }
  }, [chatId]);

  const parseInputData = (input) => {
    const cleanedInput = input.trim().replace(/\n/g, '');
    const responses = cleanedInput
      .split('data: ')
      .filter(Boolean)
      .map(line => {
        try {
          return JSON.parse(line);
        } catch (error) {
          console.error("Error parsing JSON:", error, "Line:", line);
          return null;
        }
      })
      .filter(Boolean);

    if (responses.length === 0) {
      throw new Error("No valid JSON responses found.");
    }

    const baseMessage = responses[0];
    const assistantResponses = responses.map(response => ({
      response: response.assistant || ""
    }));

    return {
      chat_id: baseMessage.chat_id,
      message_id: baseMessage.message_id,
      user_message: baseMessage.user_message,
      message_time: baseMessage.message_time,
      assistant_response: assistantResponses,
      prompt_title: baseMessage.prompt_title,
      brain_name: baseMessage.brain_name,
      brain_id: baseMessage.brain_id,
      metadata: baseMessage.metadata,
      thumbs: baseMessage.thumbs
    };
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    setLoading(true);
    let chatToSendMessage = selectedChat;

    if (!selectedChat) {
      const newChatData = {
        name: input || "New Chat",
        description: "Auto-generated",
      };
      try {
        const newChat = await creatchats(newChatData);
        onUpdateChats(newChat);
        chatToSendMessage = newChat;
      } catch (error) {
        console.error("Error creating new chat:", error);
        setLoading(false);
        return;
      }
    }

    try {
      const response = await sendMessage(chatToSendMessage.chat_id, {
        question: input,
        brain_id: localStorage.getItem("selectedBrain")
      });
      const jsonString = response.data.replace(/^data: /, '');
      const parsedMessages = parseInputData(jsonString);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          question: input,
          answer: parsedMessages.assistant_response?.map(item => item.response).join('') || "No answer received"
        },
      ]);
      setInput('');
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreatChat = async (e) => {
    e.preventDefault();
    const newChatData = {
      name: input || "New Chat",
      description: "Auto-generated",
    };
    try {
      console.log(newChatData)
      const newChat = await creatchats(newChatData);
      console.log(newChat.data)
      console.log(chatsdata)
      onUpdateChats([...chatsdata, newChat.data]);
      setSelectedChat(newChat.data);
      navigate(`/chat/${newChat.data.chat_id}`);
    } catch (error) {
      console.error("Error creating new chat:", error);
      setLoading(false);
      return;
    }
  }

  return (
    <div className="flex flex-col h-full">
      {showchat && (
        <>
          <div className="flex-grow overflow-y-auto mb-4 p-2 bg-gray-100 rounded-lg shadow-inner">
        <ul className="space-y-2">
          {messages.map((msg, index) => (
            <li key={index} className="flex flex-col">
              <div className="text-gray-800">
                <strong>Q:</strong> {msg.question || msg.body.user_message}
              </div>
              <div className="text-gray-600">
                <strong>A:</strong> {msg.answer || msg.body.assistant}
              </div>
            </li>
          ))}
        </ul>
      </div>
      
      {/* Input Section */}
      <form onSubmit={handleSendMessage} className="flex items-center space-x-2 p-2">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Ask a question..."
          className="flex-grow px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
          required
        />
        <button
          type="submit"
          className="py-3 px-4 bg-indigo-600 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 transition duration-200"
        >
          {loading ? "Sending..." : "Send"}
        </button>
      </form>
        </>
      )}

{!showchat && ( 
        <div className="flex flex-col justify-center items-center h-full">
          <p className="text-gray-500 mb-4">No chat selected. Please create a new chat.</p>
          <form onSubmit={handleCreatChat} className="flex space-x-2 w-full">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Create a chat..."
              className="flex-grow px-4 py-3 bg-black text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
            <button
              type="submit"
              className="py-3 px-4 bg-indigo-600 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 transition duration-200"
            >
              {loading ? "Creating..." : "Create Chat"}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Chat;
