import React, { useState } from 'react';
import { uploadDocument, creatchats, delchat } from '../api/api';

const DocumentUpload = ({ brainId, fetchknowledge }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;
    try {
      const res = await creatchats({ name: "New Chat" });
      const chat_id = res.data.chat_id;
      if (chat_id) {
        await uploadDocument(brainId, file, chat_id);
        alert('Document uploaded successfully!');
        await delchat(chat_id);
        if (fetchknowledge) fetchknowledge();
      }
    } catch (error) {
      console.error("Error uploading document:", error);
      alert('Failed to upload document');
    }
  };

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-lg w-full max-w-md mx-auto mt-8">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Upload Document</h2>
      <input
        type="file"
        onChange={handleFileChange}
        className="block w-full text-gray-700 border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:border-blue-500"
        required
      />
      <button
        onClick={handleUpload}
        className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md font-semibold transition duration-200"
      >
        Upload
      </button>
    </div>
  );
};

export default DocumentUpload;
